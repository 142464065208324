import * as React from "react"
import {
  Button,
  InputConnectedField,
  TextAreaConnectedField,
  CheckboxGroupConnectedField,
  FormFieldset,
  FormLegend,
  ThemeCss,
  lineHeights,
} from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import { Formik, Form } from "formik"
import {
  regionsOptions,
  languagesOptions,
  platformsOptions,
  expertiseOptions,
} from "./constants"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import axios from "axios"
import { validationSchema, FormValues } from "./IntakeForm.schema"

const AGENCIES_DIRECTORY_API_ENDPOINT = `/api/agencies-directory-submission`

export type IntakeFormProps = {
  onSuccess: () => void
}

export function IntakeForm({ onSuccess }: IntakeFormProps) {
  const [errorMsg, setErrorMsg] = React.useState(``)

  const handleSubmit = (values: FormValues) => {
    return axios.post(AGENCIES_DIRECTORY_API_ENDPOINT, values).then(data => {
      const message = data?.data?.message
      const error = data?.data?.error
      const status = data?.status

      if (error || status !== 200) {
        if (error.name === `ValidationError`) {
          const msg =
            error.errors.length > 1
              ? `${error.message}: ${error.errors.join(`, `)}`
              : error.message

          setErrorMsg(msg)
        } else {
          setErrorMsg(message)
        }

        return
      }

      onSuccess()
    })
  }

  const resetError = () => {
    if (errorMsg) {
      setErrorMsg(``)
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      initialValues={{
        introduction: ``,
        specialization: ``,
        regions: ``,
        languages: ``,
        otherLanguages: ``,
        expertises: ``,
        platforms: ``,
        name: ``,
        website: ``,
        email: ``,
        phone: ``,
        contact: ``,
        logo: ``,
        site1: ``,
        site2: ``,
        site3: ``,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form noValidate css={formCss} onChange={resetError}>
            <TextAreaConnectedField
              name="introduction"
              label="Introduction copy - This copy will be the first text Gatsby website visitors see when they land on your agency detail page. Give us your agency elevator pitch!"
              hint="max 100 words"
              required
              labelSize="L"
            />

            <TextAreaConnectedField
              name="specialization"
              label="What does your agency specialize in?"
              hint="max 100 words"
              required
              labelSize="L"
            />

            <CheckboxGroupConnectedField
              name="regions"
              label="In what regions does your agency support clients?"
              options={regionsOptions}
              required
              labelSize="L"
            />

            <CheckboxGroupConnectedField
              name="languages"
              label="Languages supported"
              options={languagesOptions}
              required
              labelSize="L"
            />

            <InputConnectedField
              name="otherLanguages"
              label="Other languages? Which ones?"
              labelSize="L"
            />

            <CheckboxGroupConnectedField
              name="expertises"
              label="Technical expertise: Is your agency experienced in any of the following?"
              options={expertiseOptions}
              required
              labelSize="L"
            />

            <CheckboxGroupConnectedField
              name="platforms"
              label="Platform Support"
              options={platformsOptions}
              required
              labelSize="L"
            />

            <FormFieldset>
              <div css={twoColumnsFieldset}>
                <FormLegend css={legendCss}>
                  <strong>Agency contact information</strong>
                  <span>
                    Please provide the contact methods you prefer clients use to
                    reach you.
                  </span>
                </FormLegend>

                <InputConnectedField
                  name="name"
                  label="Agency name"
                  required
                  labelSize="L"
                  css={wideFiledCss}
                />

                <InputConnectedField
                  name="website"
                  label="Website"
                  required
                  labelSize="L"
                  placeholder="https://www.example.com"
                />

                <InputConnectedField
                  name="email"
                  label="Email"
                  required
                  labelSize="L"
                  placeholder="summer.smith@example.com"
                />

                <InputConnectedField
                  name="phone"
                  label="Phone"
                  required
                  labelSize="L"
                  placeholder="1234567890"
                />

                <InputConnectedField
                  name="contact"
                  label="Contact name"
                  required
                  labelSize="L"
                  placeholder="Summer Smith"
                />
              </div>
            </FormFieldset>

            <FormFieldset>
              <div css={singleColumnFieldset}>
                <FormLegend css={legendCss}>
                  <span>
                    List three of your top Gatsby-Powered Client sites:
                  </span>
                </FormLegend>

                <InputConnectedField
                  name="site1"
                  label="Client website 1"
                  required
                  labelSize="L"
                  placeholder="https://www.example.com"
                />

                <InputConnectedField
                  name="site2"
                  label="Client website 2"
                  required
                  labelSize="L"
                  placeholder="https://www.example.com"
                />

                <InputConnectedField
                  name="site3"
                  label="Client website 3"
                  required
                  labelSize="L"
                  placeholder="https://www.example.com"
                />
              </div>
            </FormFieldset>

            <FormFieldset>
              <div css={singleColumnFieldset}>
                <FormLegend css={legendCss}>
                  <span>
                    Please point to a file with your agency logo. It must be a
                    publicly accessible url of a SVG or PNG (with transparent
                    background) file. It should be suitable for display on a
                    white background, and at a minimum width of 600 pixel (if
                    PNG).
                  </span>
                </FormLegend>

                <InputConnectedField
                  name="logo"
                  label="Agency logo"
                  required
                  labelSize="L"
                  placeholder="https://www.example.com/logo.(svg|png)"
                />
              </div>
            </FormFieldset>

            {errorMsg && (
              <ErrorAlert css={errorCss} includeSupport={false}>
                {errorMsg}
              </ErrorAlert>
            )}

            <Button
              type="submit"
              loading={isSubmitting}
              loadingLabel="Submitting"
              disabled={isSubmitting}
              rightIcon={<MdArrowForward />}
              size="XL"
              css={submitCss}
            >
              Submit
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

/* styles */

const formCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[9],
})

const singleColumnFieldset: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[6],
})

const twoColumnsFieldset: ThemeCss = theme => [
  singleColumnFieldset(theme),
  {
    [theme.mediaQueries.desktop]: {
      gridTemplateColumns: `1fr 1fr`,
    },
  },
]

const legendCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  marginBottom: theme.space[4],
  display: `grid`,
  gap: theme.space[3],
  lineHeight: lineHeights.default,

  span: {
    fontSize: theme.fontSizes[2],
  },

  [theme.mediaQueries.desktop]: {
    gridColumn: `1 / 3`,
  },
})

const wideFiledCss: ThemeCss = theme => ({
  [theme.mediaQueries.desktop]: {
    gridColumn: `1 / 3`,
  },
})

const submitCss: ThemeCss = theme => ({
  justifySelf: `start`,
})

const errorCss: ThemeCss = theme => ({
  margin: `-${theme.space[4]} 0`,
})
