import * as React from "react"
import Layout from "../../layout"
import SiteHead from "../../head"
import { NarrowSingleColumn } from "gatsby-theme-dashboard/src/modules/ui/layouts/Containers"
import {
  Heading,
  Text,
  Spacer,
  Card,
  CardSection,
  Link,
} from "gatsby-interface"
import { IntakeForm } from "../../components/agencies/IntakeForm"
import { IntakeFormConfirmation } from "../../components/agencies/IntakeFormConfirmation"

export type AgenciesSubmissionsProps = {
  location: Location
}

export function Head(props) {
  return <SiteHead {...props} title="Agency Directory Intake Form" />
}

export default function AgenciesSubmissions({
  location,
}: AgenciesSubmissionsProps) {
  const [formIsVisible, setFormIsVisible] = React.useState(true)

  return (
    <Layout>
      <NarrowSingleColumn>
        <main>
          <Heading as="h1">Gatsby Agency Directory Submission Form</Heading>

          <Spacer size={10} />

          {formIsVisible && (
            <Text>
              To submit your agency for possible inclusion in the Gatsby Agency
              Directory, please complete the form below. If your submission is
              accepted, we will contact agency representatives directly (using
              the email contact information provided via this form) within 3-4
              weeks after submission to confirm inclusion in the{" "}
              <Link to="/agencies">Gatsby Agency Directory</Link>.
            </Text>
          )}

          <Spacer size={8} />

          <Card>
            <CardSection>
              {formIsVisible ? (
                <IntakeForm onSuccess={() => setFormIsVisible(false)} />
              ) : (
                <IntakeFormConfirmation />
              )}
            </CardSection>
          </Card>
        </main>
      </NarrowSingleColumn>
    </Layout>
  )
}
