const transformToOptions = (items: string[]) =>
  items.map((item: string) => ({ label: item, value: item }))

export const Regions = [
  `Africa`,
  `Asia`,
  `Caribbean`,
  `Central America`,
  `Europe`,
  `Middle East`,
  `North America`,
  `Oceania`,
  `South America`,
]

export const regionsOptions = transformToOptions(Regions)

export const Languages = [
  `Arabic`,
  `Dutch`,
  `English`,
  `Finnish`,
  `French`,
  `German`,
  `Hebrew`,
  `Hindi`,
  `Indonesian`,
  `Japanese`,
  `Norwegian`,
  `Polish`,
  `Romanian`,
  `Russian`,
  `Portuguese`,
  `Spanish`,
  `Swedish`,
  `Tamil`,
  `Telugu`,
  `Thai`,
  `Turkish`,
  `Ukrainian`,
  `Welsh`,
]
export const languagesOptions = transformToOptions(Languages)

export const Platforms = [
  `Contentful`,
  `Contentstack`,
  `DatoCMS`,
  `Drupal`,
  `HubSpot`,
  `Kontent`,
  `Prismic`,
  `Sanity`,
  `Segment.io`,
  `Shopify`,
  `Storyblok`,
  `Strapi`,
  `WordPress`,
]
export const platformsOptions = transformToOptions(Platforms)

export const Expertise = [`eCommerce`, `Design`, `UI/UX design`, `Content`]
export const expertiseOptions = transformToOptions(Expertise)
